<template>
  <div>
    <!-- <van-nav-bar
      title="API授权"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft()"
    /> -->
    <nav-bar style="background: #ffffff;" class="titleStyle" title="公告"></nav-bar>
    <div
      class="gonggao_list"
      @click="goxiangqing(item)"
      v-for="item in list"
      :key="item.id"
    >
      <span class="top">{{ item.title }}</span>
      <span class="two">{{ item.create_at }}</span>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.getlist();
  },
  data() {
    return {
      list: [],
    };
  },
  methods: {
    getlist() {
      this.$post2("Article/Api/Index/listArticle").then((res) => {
        console.log("公告ListTable：" + res.list);
        this.list = res.list;
        // var listData = res
        // console.log(listData)
      });
    },
    goxiangqing(e) {
      this.$router.push({
        path: "/tongzhi",
        query: {
          id: e.id,
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>

.nav_bar{
   background: #ffffff;
}

.nav_bar_por{
  background: #ffffff;
}

.titleStyle{
  background: #ffffff;
}

.nav_bar{
  background: red;
}
.nav_bar_por {
  background: #ffffff;
}
.gonggao_list {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 65px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  .top {
    color: #999999;
    font-size: 0.3rem;
    margin-left: 0.4rem;
    margin-top: 14px;
  }
  .two {
    color: #999999;
    font-size: 0.28rem;
    margin-left: 0.4rem;
    margin-top: 2px;
  }
}
</style>